/**
 * Soleil AB
 * Author: Anders Rosen
 * Date: Mon Oct 04 2021 08:57:27 GMT+0200 (centraleuropeisk sommartid)
 * Build script version: 2.2.4
 */
(function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof browserify_require=="function"&&browserify_require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);throw new Error("Cannot find module '"+o+"'")}var f=n[o]={exports:{}};t[o][0].call(f.exports,function(e){var n=t[o][1][e];return s(n?n:e)},f,f.exports,e,t,n,r)}return n[o].exports}var i=typeof browserify_require=="function"&&browserify_require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
"use strict";

svDocReady(function () {
  if (typeof jQuery != 'undefined') {
    jQuery(document).ready(function ($) {
      var filetypes = /\.(zip|exe|pdf|doc*|xls*|ppt*|mp3)$/i;
      var baseHref = '';
      if (jQuery('base').attr('href') != undefined) baseHref = jQuery('base').attr('href');
      jQuery('a').each(function () {
        var href = jQuery(this).attr('href');

        if (href && href.match(/^https?\:/i) && !href.match(document.domain)) {
          jQuery(this).click(function () {
            var extLink = href.replace(/^https?\:\/\//i, '');

            _gaq.push(['_trackEvent', 'External', 'Click', extLink]);

            if (jQuery(this).attr('target') != undefined && jQuery(this).attr('target').toLowerCase() != '_blank') {
              setTimeout(function () {
                location.href = href;
              }, 200);
              return false;
            }
          });
        } else if (href && href.match(/^mailto\:/i)) {
          jQuery(this).click(function () {
            var mailLink = href.replace(/^mailto\:/i, '');

            _gaq.push(['_trackEvent', 'Email', 'Click', mailLink]);
          });
        } else if (href && href.match(filetypes)) {
          jQuery(this).click(function () {
            var extension = /[.]/.exec(href) ? /[^.]+$/.exec(href) : undefined;
            var filePath = href;

            _gaq.push(['_trackEvent', 'Download', 'Click-' + extension, filePath]);

            if (jQuery(this).attr('target') != undefined && jQuery(this).attr('target').toLowerCase() != '_blank') {
              setTimeout(function () {
                location.href = baseHref + href;
              }, 200);
              return false;
            }
          });
        }
      });
    });
  }
});

},{}],2:[function(require,module,exports){
"use strict";

(function () {
  svDocReady(function () {
    $svjq('body').on('click', '.sol-collapse-title', function () {
      if ($svjq(this).hasClass("open")) {
        $svjq(this).removeClass("open");
      } else {
        $svjq(this).addClass("open");
      }

      $svjq(this).next(".sol-collapse-content").slideToggle();
    });
  });
})();

},{}],3:[function(require,module,exports){
"use strict";

svDocReady(function () {
  /*
  $svjq('div.responsive-button').click(function() {
     console.log(this);
  });
  */
  $svjq('#responsive-search').click(function () {
    var search = $svjq('div.mobile-search-hook');

    if ($svjq(this).attr("aria-expanded") == "true") {
      $svjq(this).attr("aria-expanded", "false");
      $svjq(search).attr("aria-hidden", "true");
    } else {
      $svjq(this).attr("aria-expanded", "true");
      $svjq(search).attr("aria-hidden", "false");
    }

    $svjq('div.mobile-search-hook').slideToggle();
  });
  $svjq('#responsive-menubars').click(function () {
    var menu = $svjq('div#responsive-main-menu');

    if ($svjq(this).attr("aria-expanded") == "true") {
      $svjq(this).attr("aria-expanded", "false");
      $svjq(menu).attr("aria-hidden", "true");
    } else {
      $svjq(this).attr("aria-expanded", "true");
      $svjq(menu).attr("aria-hidden", "false");
    }

    $svjq(menu).slideToggle();
  });
});

},{}],4:[function(require,module,exports){
"use strict";

},{}],5:[function(require,module,exports){
"use strict";

//
// Exempel hur man använder denna modul:
//
// var toggle = Soleil.Toogle.create({
//   parentSelector:'.sol-toogle-parent',
//   headerSelector:'.sol-toogle-header',
//   contentSelector:'.sol-toogle-content',
//   [slideTime:100,]
//   [showContentOnCreation:true]
// });
//
var Soleil = window.Soleil || {};
svDocReady(function () {
  Soleil.Toggle = function (jq) {
    var toggle = function toggle(header, content) {
      var self = this;
      content.slideToggle(self.options.slideTime);
      header.toggleClass(self.options.visibleClass);

      if (self.onToggleCallback) {
        onToggleCallback.call(self, header, content);
      }
    };

    var show = function show(header, content) {
      var self = this;
      content.show();
      header.addClass(self.options.visibleClass);

      if (self.onExpandCallback) {
        onExpandCallback.call(self, header, content);
      }
    };

    var hide = function hide(header, content) {
      var self = this;
      content.hide();
      header.removeClass(self.options.visibleClass);

      if (self.onCollapseCallback) {
        onCollapseCallback.call(self, header, content);
      }
    };

    var checkMandatorySelector = function checkMandatorySelector(selector) {
      var self = this;
      var elements = jq(self.options[selector]);

      if (elements.length === 0) {
        throw "No element with selector '" + selector + "' found";
      }
    };

    var init = function init() {
      var self = this;
      jq(self.options.parentSelector).each(function () {
        // OBS! jQuery this
        var header = jq(this).find(self.options.headerSelector);
        var content = jq(this).find(self.options.contentSelector);
        header.on('click', function () {
          toggle.call(self, header, content);
        });

        if (!self.options.showContentOnCreation) {
          hide.call(self, header, content);
        }
      });
    };

    return {
      create: function create(opt, createCallback) {
        // Initierar defaultvärden
        opt.slideTime = opt.slideTime || 200;
        opt.showContentOnCreation = opt.showContentOnCreation || false; // Skapar objektet

        var toggleObj = {
          options: opt,
          toggle: toggle,
          onExpand: function onExpand(callback) {
            this.onExpandCallback = callback;
          },
          onCollapse: function onCollapse(callback) {
            this.onCollapseCallback = callback;
          },
          onToggle: function onToggle(callback) {
            this.onToggleCallback = callback;
          }
        }; // Kollar att alla parametrar har skickats med

        ['parentSelector', 'headerSelector', 'contentSelector'].forEach(function (selector) {
          checkMandatorySelector.call(toggleObj, selector);
        }); // Initierar objektet

        init.call(toggleObj);

        if (createCallback) {
          createCallback();
        }

        return toggleObj;
      }
    };
  }(jQuery);
});
window.Soleil = Soleil;
module.exports = Soleil.Toggle;

},{}]},{},[1,2,3,4,5]);
